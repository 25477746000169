<template>
  <div :class="'speech-bubble speech-bubble--' + size">
    <div ref="speechBubbleInner" class="speech-bubble__inner">
      <header class="speech-bubble__header">
        <slot name="header"></slot>
      </header>
      <main class="speech-bubble__content">
        <slot name="content"></slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpeechBubble',
  props: {
    color: {
      type: String,
      default: '#BA56A0',
    },
    size: {
      type: String,
      default: 'small', // large | small
    },
  },
};
</script>

<style scoped>
.speech-bubble {
  position: relative;
  width: calc(100% - 40px);
  max-height: 70%;
  margin: 100px 0 20px 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #339b47;
  border-radius: 20px;
}

@media only screen and (min-width: 425px) {
  .speech-bubble {
  }
}

@media only screen and (min-width: 600px) {
  .speech-bubble {
    width: 75%;
    margin: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .speech-bubble {
    width: 65%;
  }
}

/* Inner */
.speech-bubble__inner {
  position: relative;
  padding: 20px;
  z-index: 15;
  height: 100%;

  color: white;
  font-family: 'arial', 'Helvetica', 'sans-serif';
}

@media only screen and (min-width: 1000px) {
  .speech-bubble__inner {
    min-width: 425px;
    max-width: 650px;
  }
}

.speech-bubble--large .speech-bubble__inner {
  max-width: 700px;
}

/* Header */
.speech-bubble__header {
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  font-family: 'MadimiOne', 'Helvetica', 'sans-serif';
}

@media only screen and (min-width: 800px) {
  .speech-bubble__header {
    margin-bottom: 25px;
  }
}

.speech-bubble__header h2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 600px) {
  .speech-bubble__header h2 {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 800px) {
  .speech-bubble__header h2 {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 1000px) {
  .speech-bubble__header h2 {
    font-size: 4.5rem;
  }
}

.speech-bubble__header p {
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 10px 0 0 0;
}

@media only screen and (min-width: 600px) {
  .speech-bubble__header p {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 800px) {
  .speech-bubble__header p {
    font-size: 2.6rem;
  }
}

.speech-bubble--large .speech-bubble__header p {
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 10px 0 0 0;
}

@media only screen and (min-width: 600px) {
  .speech-bubble--large .speech-bubble__header p {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 800px) {
  .speech-bubble--large .speech-bubble__header p {
    font-size: 2.4rem;
  }
}

/* Content */
.speech-bubble__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
