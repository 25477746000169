<template>
  <transition appear :css="false" @enter="enter" @leave="leave" mode="in-out">
    <div class="overlay">
      <div class="overlay__content">
        <RouterView :category="category"></RouterView>
      </div>
      <div ref="background" class="overlay__background">&nbsp;</div>
    </div>
  </transition>
</template>

<script>
import { categories } from '@/game';
import { timer } from '@/timer';
import { gsap } from 'gsap';

export default {
  name: 'CategoryRoute',
  data() {
    return {
      category: undefined,
    };
  },
  props: ['categoryId'],
  created() {
    // Todo: Get category from remaining questions or 404
    this.category = categories.all[this.categoryId];
  },
  methods: {
    enter(el, done) {
      categories.scoreVisible = false;
      if (timer.isEnabled) {
        timer.show();
      }
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.background,
          {
            left: '100%',
            autoAlpha: 0,
          },
          {
            autoAlpha: 0.75,
            left: 0,
            duration: 0.5,
            ease: 'power4.out',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.background,
          {
            left: 0,
            autoAlpha: 0.75,
          },
          {
            autoAlpha: 0,
            left: '100%',
            duration: 0.5,
            ease: 'power1.out',
          }
        );
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
}

.overlay__content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}

.overlay__background {
  background-color: white;
  opacity: 0.75;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
