<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="finished-route">
        <div class="container">
          <div ref="island" class="row island">
            <div class="island__content">
              <h2>You've completed the Global Goals Spinner!</h2>
              <p>You scored {{ score }} out of {{ total }}.</p>
              <p>Would you like to play again?</p>
              <div class="island__footer">
                <RouterLink :to="{ name: 'title' }" replace>
                  <Button :color="'orange'" :size="'small'">Play Again</Button>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';
import { gsap } from 'gsap';
import { categories } from '@/game';

export default {
  name: 'IntroRoute',
  components: {
    Button,
    RouteWrapper,
  },
  computed: {
    score() {
      return categories.correct.length;
    },
    total() {
      return categories.all.length;
    },
  },
  methods: {
    enter(el, done) {
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.island,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.5,
            autoAlpha: 1,
            ease: 'power1.out',
          },
          0.5
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.island,
          {
            y: 0,
          },
          {
            y: '-200%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        );
    },
  },
};
</script>

<style scoped>
.finished-route {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  z-index: 5;
}

@media only screen and (min-width: 875px) {
  .container {
    width: 80%;
    padding: 0;
    justify-content: space-around;
  }
}

@media only screen and (min-width: 1000px) {
  .container {
    width: 70%;
    justify-content: center;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    width: 900px;
  }
}

/**
 * Header
 **/
.header {
  height: 200px;
}

@media only screen and (min-width: 375px) {
  .header {
    height: 150px;
  }
}

@media only screen and (min-width: 420px) {
  .header {
    /* height: auto; */
  }
}

@media only screen and (min-width: 1000px) {
  .header {
    position: fixed;
    top: 15px;
    left: 50%;
    width: 60%;
    transform: translate3d(-50%, 0, 0);
  }
}

/**
 * Island and content
 */
.island {
  position: relative;
  margin: 0 20px;
  padding: 20px;
  color: white;
  font-size: 1.6rem;
  text-align: center;
  background-color: #529951;
  border-radius: 20px;
  border-radius: 20px;
  overflow-y: auto;
}

@media only screen and (min-width: 600px) {
  .island {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 875px) {
  .island {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 50px;
    font-size: 2rem;
    overflow: inherit;
    margin: 0;
  }
}

/**
 * Content
 */
.island__content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 1000px) {
  .island__content {
    margin: 0 100px;
  }
}

.island__content h2 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 600px) {
  .island__content h2 {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 800px) {
  .island__content h2 {
    font-size: 3.5rem;
  }
}

.island__content p {
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 10px 0 0 0;
}

@media only screen and (min-width: 600px) {
  .island__content p {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 800px) {
  .island__content p {
    font-size: 2.6rem;
  }
}

/**
 *
 */
.island__footer {
  margin-top: 15px;
}

@media only screen and (min-width: 600px) {
  .island__footer {
    margin-top: 25px;
  }
}
</style>
