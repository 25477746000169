<template>
  <transition appear :css="false">
    <div ref="stopwatch" class="stopwatch" :class="classNames">
      <span class="stopwatch__label">Time</span>
      <div class="stopwatch__time">{{ time }}</div>
    </div>
  </transition>
</template>

<script>
import { timer } from '@/timer';

export default {
  name: 'Stopwatch',
  data() {
    return {
      timer,
    };
  },
  computed: {
    time: () => {
      return String(timer.time).padStart(2, '0');
    },
    classNames() {
      return 'stopwatch--is-visible stopwatch--is-expanded';
    },
  },
};
</script>

<style scoped>
.stopwatch {
  overflow: hidden;
  font-family: 'MadimiOne', Arial, Helvetica, sans-serif;
  position: fixed;
  top: 10vh;
  right: 5vw;
  width: 12.5vw;
  height: 15vh;
  z-index: -1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: transform 200ms linear;
  transform: translate3d(100%, 0, 0);
  align-items: end;
  background-image: url('~@/assets/imgs/timer-background.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.stopwatch--is-visible.stopwatch--is-expanded {
  transform: translate3d(0, 0, 0);
}

.stopwatch--is-visible:not(.stopwatch--is-expanded) {
  transform: translate3d(60px, 0, 0);
}

@media only screen and (min-width: 600px) {
  .stopwatch--is-visible:not(.stopwatch--is-expanded) {
    transform: translate3d(12vw, 0, 0);
  }
}

.stopwatch__label {
  color: #ffeaca;
  transform: translate3d(-50%, 0, 0);
  font-size: 2rem;
  line-height: 1;
  padding-right: 1.5vw;
}

.stopwatch__time {
  font-family: 'MadimiOne', Arial, Helvetica, sans-serif;
  font-size: 5rem;
  line-height: 1;
  color: white;
  text-align: right;
  width: 100%;
  padding-right: 1.5vw;
  padding-bottom: 0.5vh;
}

@media only screen and (min-width: 1200px) {
  .stopwatch__time {
    font-size: 6.5rem;
  }
}
</style>
