<template>
  <transition appear :css="false">
    <div ref="score" class="score" :class="classNames">
      <span class="score__value">{{ score }}</span>
      <span class="score__label">Score</span>
    </div>
  </transition>
</template>

<script>
import { categories } from '@/game';

export default {
  name: 'Score',
  data() {
    return {};
  },
  computed: {
    score: () => {
      return String(categories.correct.length);
    },
    classNames() {
      return `${categories.scoreVisible ? 'score--is-visible' : ''}`;
    },
  },
};
</script>

<style scoped>
.score {
  position: absolute;
  bottom: 10vh;
  right: 8vw;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MadimiOne', Arial, Helvetica, sans-serif;
  font-size: 5rem;
  border-radius: 50%;
  line-height: 1;
  color: white;
  text-align: center;
  height: 100px;
  width: 100px;
  transition: all 200ms linear;
  background-image: url('~@/assets/imgs/score-background.png');
  background-position: center center;
  background-size: 100px 100px;
}

.score--is-visible {
  transform: translate3d(0, 0, 0);
}

.score__label {
  position: absolute;
  top: 15%;
  left: 50%;
  opacity: 0.5;
  transform: translate3d(-50%, 0, 0);
  font-size: 2rem;
  line-height: 1;
}

.score__value {
  margin-top: 20%;
}

@media only screen and (min-width: 1200px) {
  .score {
    font-size: 6rem;
    height: 130px;
    width: 130px;
    background-size: 130px 130px;
  }
}
</style>
