<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <section class="title-route">
        <img
          ref="titleImage"
          class="title-image"
          src="~@/assets/imgs/intro-header.png"
        />
        <div ref="startButton">
          <Button @click="start()">Start</Button>
        </div>
        <img
          ref="doodle1"
          class="doodle-1"
          src="~@/assets/imgs/doodle-1.png"
          alt="Doodle"
        />
        <img
          ref="doodle2"
          class="doodle-2"
          src="~@/assets/imgs/doodle-2.png"
          alt="Doodle"
        />
      </section>
    </RouteWrapper>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
import Button from '@/components/Button';
import RouteWrapper from '@/components/RouteWrapper';

export default {
  name: 'TitleRoute',
  components: {
    Button,
    RouteWrapper,
  },
  methods: {
    start() {
      this.$router.replace({ name: 'intro' });
    },
    enter(el, done) {
      // Add entering animations
      gsap
        .timeline({
          onComplete: done,
          delay: 0.5,
        })
        .fromTo(
          this.$refs.titleImage,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        )
        .fromTo(
          this.$refs.startButton,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        )
        .fromTo(
          this.$refs.doodle1,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        )
        .fromTo(
          this.$refs.doodle2,
          {
            y: '40px',
            autoAlpha: 0,
          },
          {
            y: 0,
            duration: 0.25,
            autoAlpha: 1,
          }
        );

      // Jangle the button
      this.buttonJangleTimeline = gsap
        .timeline({
          repeat: -1,
          repeatDelay: 6,
          delay: 6,
        })
        .fromTo(
          this.$refs.startButton,
          0.1,
          {
            rotate: '-3',
          },
          {
            rotate: 3,
            repeat: 5,
            yoyo: true,
            ease: 'power1.out',
          }
        );
    },
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.titleImage,
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            y: '-40px',
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.doodle1,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.doodle2,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.startButton.$el,
          {
            y: 0,
            autoAlpha: 1,
          },
          {
            y: '-40px',
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        );
    },
  },
};
</script>

<style scoped>
.title-route {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

@media only screen and (min-width: 700px) {
  .title-route {
    padding: 0 80px;
  }
}

.title-image {
  width: 90%;
  height: auto;
  margin-bottom: 24px;
}

@media only screen and (min-width: 700px) {
  .title-image {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) {
  .title-image {
    width: 800px;
  }
}

.doodle-1 {
  z-index: -1;
  position: absolute;
  width: 20vw;
  bottom: 10vh;
  left: 8vw;
}

.doodle-2 {
  z-index: -1;
  position: absolute;
  width: 11vw;
  top: 15vh;
  right: 8vw;
}
</style>
