<template>
  <transition appear :css="false">
    <div class="loading">
      <div ref="logoContent" class="loading__content">
        <img
          @load="logoLoaded"
          class="loading__logo"
          src="~@/assets/imgs/irish-aid-logo.png"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap';
export default {
  name: 'LoadingRoute',
  created() {
    const images = [
      'arrow.png',
      'background.png',
      'corner-logo.png',
      'doodle-1.png',
      'doodle-2.png',
      'magazine-cover-one.jpg',
      'magazine-cover-two.jpg',
      'green-tick.png',
      'yellow-tick.png',
      'cross.png',
      'intro-header.png',
      'score-background.png',
      'timer-background.png',
    ].map((fileName) => {
      return new Promise((resolve, reject) => {
        let img = new Image();
        let url = require(`@/assets/imgs/${fileName}`);
        img.onload = () => {
          resolve(true);
        };
        img.src = url;
      });
    });
    Promise.all(images).then(() => {
      setTimeout(() => {
        this.$router.replace({ name: 'title' });
      }, 2000);
    });
  },
  beforeRouteLeave(to, from, next) {
    gsap
      .timeline({
        onComplete: next,
      })
      .fromTo(
        this.$refs.logoContent,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 1,
        }
      );
  },
  methods: {
    logoLoaded() {
      gsap.timeline().fromTo(
        this.$refs.logoContent,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        }
      );
    },
  },
};
</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(0.95) rotate(-3deg);
  }

  50% {
    transform: scale(1) rotate(-3deg);
  }

  100% {
    transform: scale(0.95) rotate(-3deg);
  }
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.loading__content {
  transform: scale(1) rotate(-3deg);
  animation: pulse 2s infinite;
  z-index: 5;
  opacity: 0;
  max-width: 600px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading__logo {
  width: 100%;
}
</style>
