<template>
  <button
    :class="'button button--' + size + ' button--' + color"
    @click="$emit('click', $event)"
    @mousedown="buttonDown()"
  >
    <span class="button__content"><slot></slot></span>
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    size: {
      type: String,
      default: 'large',
    },
    color: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      pressed: undefined,
      depressed: undefined,
    };
  },
  methods: {
    buttonDown() {
      this.pressed = Date.now();
      new Audio('/sounds/button.mp3').play();
    },
  },
};
</script>

<style scoped>
.button {
  position: relative;
  display: block;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 20px;
  color: white;
  font-size: 3rem;
  line-height: 1;
  text-decoration: none;
  border-radius: 14px;
  font-family: 'MadimiOne', 'Helvetica', 'sans-serif';
  transition: all ease-out 100ms;
  outline: none;
  min-width: 125px;
}

@media only screen and (min-width: 1200px) {
  .button {
    font-size: 4.5rem;
    min-width: 200px;
    padding: 20px 40px;
  }
}

.button:hover {
  transform: scale(1.05) rotate(-3deg) translate3d(0, -2px, 0);
}

.button__content {
  display: block;
  position: relative;
  transition: transform ease-out 150ms;
  z-index: 5;
}

.button:hover .button__content {
  transform: scale(1.05);
}

.button:active {
  top: -3px;
  left: -3px;
}

/* Sizes */
.button--small {
  font-size: 3rem;
}

@media only screen and (min-width: 400px) {
  .button--small {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1000px) {
  .button--small {
    font-size: 4rem;
  }
}

/* Colors */
.button--default {
  background-color: #339b47;
}

.button--green {
  background-color: #005952;
}

.button--blue {
  background-color: #007dbb;
}

.button--orange {
  background-color: #e5331c;
}

.button--dark-blue {
  background-color: #1f3361;
}
</style>
