<template>
  <canvas class="confetti" ref="canvas"></canvas>
</template>
<script>
import confetti from 'canvas-confetti';
export default {
  name: 'Confetti',
  data() {
    return {
      confetti: undefined,
    };
  },
  mounted() {
    this.confetti = confetti.create(this.$refs.myDiv, {
      resize: true,
      useWorker: true,
    });
  },
  methods: {
    fire(ratio, opts) {
      this.confetti(
        Object.assign({}, { origin: { y: 0.7 } }, opts, {
          particleCount: Math.floor(200 * ratio),
        })
      );
    },
    blast() {
      this.fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      this.fire(0.2, {
        spread: 60,
      });
      this.fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      this.fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      this.fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    },
  },
};
</script>

<style>
.confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
