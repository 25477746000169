const sdgQuestions = [
  {
    id: 0,
    title: 'No Poverty',
    color: '#e3081f',
    questions: [
      {
        question: 'How can you tell that someone is living in poverty?',
        options: [
          "The person doesn't have a smartphone",
          'The person is not able to fulfil their basic needs, such as food, healthcare, education, among others',
          'The person is not well dressed',
        ],
        answers: [2],
      },
      {
        question:
          'Are there fewer people living in poverty now than there were 25 years ago?',
        options: [
          'No, 1 billion more people are now living in poverty',
          'Yes, 1 billion people have been lifted out of poverty',
        ],
        answers: [2],
      },
      {
        question: 'How many people are living in extreme poverty worldwide?',
        options: [
          'Around 800 people',
          'Around 8,000 people',
          'Almost 700 million people',
        ],
        answers: [3],
        extraText:
          'This means that they live with less than 2.15$ per day (2 euros). Just over half of these people live in Sub-Saharan Africa. One in every eight people in Ireland lives on an income below the poverty line (13.1% of the population). This corresponds to almost 670,000 people.',
      },
      {
        question:
          'In 1990, almost four in ten people (38%) lived in extreme poverty. How many people live in extreme poverty now?',
        options: [
          "1 in 10 (08.5% of the World's Population)",
          '3 in 10',
          '5 in 10',
        ],
        answers: [1],
      },
    ],
  },
  {
    id: 1,
    title: 'Zero Hunger',
    color: '#cf8d29',
    questions: [
      {
        question: 'Is enough food produced in the world to feed everyone?',
        options: [
          'Enough healthy food is produced but not enough sweets and fizzy drinks',
          'Yes',
        ],
        answers: [2],
        extraText:
          "We have more than enough food in the world to feed everyone, but some of us have too much and others have too little and can't afford the food they need",
      },
      {
        question:
          'True or false: the number of starving people in the world is decreasing.',
        options: ['True', 'False'],
        answers: [1],
      },
      {
        question:
          'How many people in the world are moderately or severely food insecure?',
        options: [
          'Almost 90 people',
          'Almost 29,000 people',
          'Almost 2.3 billion people',
        ],
        answers: [3],
        extraText: "It's almost 30% of the world's population)",
      },
      {
        question: 'Which of these statements is true?',
        options: [
          'You need at least one sweetened fizzy drink per day to stay healthy',
          'There is enough food to feed the world',
          "You can still stay healthy if you don't eat vegetables and fruits",
        ],
        answers: [2],
      },
      {
        question: 'What is malnutrition?',
        options: [
          "When you don't have a three-course meal",
          "When you don't eat meat every day",
          'When you do not have access to enough healthy food for a long period of time',
        ],
        answers: [3],
      },
    ],
  },
  {
    id: 2,
    title: 'Good Health & Well-being',
    color: '#339b47',
    questions: [
      {
        question: 'What is the average life expectancy in the world?',
        options: ['51 years', '62 years', '72 years FYI 72.27'],
        answers: [3],
      },
      {
        question:
          'Choose two things that would be most helpful in making children under 5 healthy and well',
        options: [
          'Nutritious food',
          'Mobile phone',
          'Clean drinking water',
          'Fizzy drinks',
        ],
        answers: [1, 3],
      },
      {
        question:
          'How many minutes of exercise should children between 5 and 17 do every day?',
        options: [
          '60 minutes',
          '30 minutes',
          "Children don't need to exercise everyday",
        ],
        answers: [1],
      },
      {
        question:
          'Which of these insects helps to spread the disease, malaria?',
        options: ['Mosquitoes', 'Dragonflies', 'Fireflies'],
        answers: [1],
      },
    ],
  },
  {
    id: 3,
    title: 'Quality Education',
    color: '#c21729',
    questions: [
      {
        question:
          'Most of the children who do not go to school live on which continent?',
        options: ['Europe', 'Asia', 'America', 'Africa'],
        answers: [4],
        extraText:
          'Sub-Saharan Africa. This is why Irish Aid work closely with this region of the world to promote Education for both girls and boys',
      },
      {
        question:
          'True or false: fewer girls than boys attend school worldwide.',
        options: ['False', 'True'],
        answers: [2],
      },
      {
        question: 'Education...',
        options: [
          'Will make it hard for you to get a job',
          "Will help you get a job and help you to attend to all your life's needs.",
          'Is only useful for very smart kids',
        ],
        answers: [2],
      },
      {
        question: 'Quality education is good for...',
        options: ['Improving health', 'Reducing poverty', 'Both'],
        answers: [3],
      },
      {
        question:
          'What percentage of adults today are literate, meaning they can read and write?',
        options: ['87%', '64%', '32%'],
        answers: [1],
      },
    ],
  },
  {
    id: 4,
    title: 'Gender Equality',
    color: '#e5331c',
    questions: [
      {
        question:
          'Only one country in the world had more women than men in parliament in 2017. Which country is it?',
        options: ['The United Kingdom', 'France', 'Rwanda', 'Peru'],
        answers: [3],
      },
      {
        question:
          'Which country was the first in the world to give women full political rights (the right to vote and be elected)?',
        options: ['France', 'The United States of America', 'Finland', 'China'],
        answers: [3],
      },
      {
        question:
          'How many countries (out of 193- UN stats) in the world have a woman as president or head of state currently?',
        options: ['5', '26', '11'],
        answers: [2],
        extraText:
          'As of 10 January 2024, there are 26 countries where 28 women serve as Heads of State and/or Government At the current rate, gender equality in the highest positions of power will not be reached for another 130 years',
      },
      {
        question: 'Who should do the cooking and cleaning in the house?',
        options: [
          'The males in the house',
          'The females in the house',
          'Everyone should help out',
        ],
        answers: [3],
      },
      {
        question:
          'What percentage of researchers in science and technology are women?',
        options: ['50%', '30%', '70%'],
        answers: [2],
      },
    ],
  },
  {
    id: 5,
    title: 'Clean Water & Sanitation',
    color: '#004d85',
    questions: [
      {
        question: 'How can you use less water?',
        options: [
          "There's no shortage of water where I live, I can use as much as I like",
          'By taking a shower rather than a bath',
          'By drinking sweetened fizzy drinks instead of water',
        ],
        answers: [2],
      },
      {
        question: 'Potable water is:',
        options: [
          'Water that is safe to drink',
          'Water that we can find in flower pots',
          'Water that looks clean',
        ],
        answers: [1],
      },
      {
        question:
          'Where does most of the wastewater resulting from human activities go?',
        options: [
          'It is treated and recycled',
          'It is stored in safe places',
          'It is discharged into rivers or seas without any treatment against pollution',
        ],
        answers: [3],
      },
      {
        question: 'Which statement is correct?',
        options: [
          'More people have a mobile phone than a toilet',
          "Most people have toilets but can't afford a mobile phone",
          'Everyone has both a mobile phone and a toilet',
        ],
        answers: [1],
      },
      {
        question: 'What percentage of water in the world is potable?',
        options: ['Only 3%', 'Half of all water, 50%', 'Almost all of it, 97%'],
        answers: [1],
      },
    ],
  },
  {
    id: 6,
    title: 'Affordable & Clean Energy',
    color: '#a99562',
    questions: [
      {
        question: 'Spot the wrong answer. Clean energy can be produced with...',
        options: ['Coal', 'Sun', 'Wind', 'Waves'],
        answers: [1],
      },
      {
        question: 'What does energy efficiency mean?',
        options: [
          'When a lamp gives a bright light',
          'When you can use energy without having to worry about your consumption',
          'A device or building that uses relatively little energy to provide it with the power it needs',
        ],
        answers: [3],
      },
      {
        question:
          'Global warming happens mainly because of an increase of a certain gas in the atmosphere. Which gas is it?',
        options: ['Ozone', 'Carbon dioxide', 'Helium'],
        answers: [2],
      },
      {
        question: 'Some energy sources are renewable, why?',
        options: [
          "Because they don't pollute",
          'Because they are brand new',
          'Because they can be replenished by nature in a short time',
        ],
        answers: [3],
      },
      {
        question: 'Which is a renewable energy source?',
        options: ['Oil', 'Gas', 'Sunlight'],
        answers: [3],
      },
    ],
  },
  {
    id: 7,
    title: 'Good Jobs & Economic Growth',
    color: '#941730',
    questions: [
      {
        question:
          'Should women be paid the same as men if they have the same job?',
        options: [
          'Yes of course, men and women have the same rights',
          'No, men should be paid more because they are stronger',
        ],
        answers: [1],
      },
      {
        question:
          'If you have a job, does that mean you are safe from poverty?',
        options: [
          'Yes, because you work to earn money',
          'No, you can have a job and live in poverty',
        ],
        answers: [2],
      },
      {
        question: 'What does "economic growth" mean?',
        options: [
          'You save more money every month',
          'Prices are increasing',
          'An increase in the value of goods and services produced by a country',
        ],
        answers: [3],
      },
      {
        question: 'How many people are jobless worldwide?',
        options: ['2 million', '20 million', '200 million (235)'],
        answers: [3],
      },
      {
        question: 'True or false: every adult possesses a bank account.',
        options: ['True', 'False'],
        answers: [2],
      },
    ],
  },
  {
    id: 8,
    title: 'Industry, Innovation & Infrastructure',
    color: '#4a4a4a',
    questions: [
      {
        question: 'What is a sustainable factory?',
        options: [
          'A factory that has been built long ago and is still functioning',
          'A factory that produces toxic waste',
          'A factory that causes no damage to the environment and is respectful/abiding by the rules and regulations in place, advocating for climate action.',
        ],
        answers: [3],
      },
      {
        question: 'True or false: everyone has access to the internet.',
        options: ['True', 'False'],
        answers: [2],
      },
      {
        question: 'True or false: everyone has access to electricity.',
        options: ['True', 'False'],
        answers: [2],
      },
      {
        question:
          'Which one of these innovations is the best at fighting climate change?',
        options: ['An electric car', 'A diesel car', 'A petrol car'],
        answers: [1],
      },
      {
        question: 'What infrastructure is important for quality education?',
        options: [
          'Playgrounds and snacks',
          'Clean schools, teachers and books',
          'Soft beverage dispenser',
        ],
        answers: [2],
      },
    ],
  },
  {
    id: 9,
    title: 'Reduce Inequality',
    color: '#de0a78',
    questions: [
      {
        question: 'What is the best way to fight poverty?',
        options: [
          'Do nothing and ignore the problem',
          'Share the wealth produced in the world',
          'Prevent foreigners from entering your country',
        ],
        answers: [2],
      },
      {
        question:
          'True or false: in the last 25 years, the number of people in extreme poverty has NOT gone down',
        options: ['True', 'False'],
        answers: [2],
      },
      {
        question: 'At school ...',
        options: [
          'Girls should learn to knit and cook',
          'Boys should learn science and technology',
          'Girls and boys should have access to equal learning opportunities',
        ],
        answers: [3],
      },
      {
        question:
          'To reduce health inequalities all children should have access to...',
        options: [
          'Clean drinking water',
          'Medical care',
          'Vaccines',
          'All of the above',
        ],
        answers: [4],
      },
      {
        question: 'We can fight inequalities by:',
        options: [
          'Making sure that all children have access to a good education',
          'Making sure that everyone is wearing the same clothes',
          'Making sure that everyone watches the same TV',
        ],
        answers: [1],
      },
    ],
  },
  {
    id: 10,
    title: 'Sustainable Cities & Communities',
    color: '#cf8d29',
    questions: [
      {
        question:
          'Solutions to save energy and protect the planet already exist. Which of these are not Real?',
        options: ['Bicycles', 'Electric buses', 'Flying boats'],
        answers: [3],
      },
      {
        question: 'What is renewable energy?',
        options: [
          'Energy that comes from natural sources and never runs out',
          'Energy from outer space',
          'A form of energy used by the pharaohs of ancient Egypt',
        ],
        answers: [1],
      },
      {
        question:
          "In 2030, what percentage of the world's population will live in urban areas (cities)?",
        options: ['60%', '100%', '20%'],
        answers: [1],
      },
    ],
  },
  {
    id: 11,
    title: 'Responsible Consumption & Production',
    color: '#005952',
    questions: [
      {
        question: 'Statement: there are plenty of fish in the sea',
        options: [
          'True, I can eat as many as I want!',
          'False, Overfishing, pollution and climate change are reducing the populations of fish in the oceans',
        ],
        answers: [2],
      },
      {
        question: 'How much food is wasted every day worldwide?',
        options: [
          'None, as all the food is eaten or frozen',
          'One third of all food produced',
          'Very little, as people have dramatically reduced the waste',
        ],
        answers: [2],
      },
      {
        question:
          'Oil is the most polluting industry in the world. Which industry comes second?',
        options: ['Coal extraction', 'Textile industry', 'Cell phone industry'],
        answers: [2],
      },
      {
        question:
          'Plastic bags are a big problem for our environment. Which country was the first to ban plastic bags?',
        options: ['Sweden', 'Russia', 'Bangladesh'],
        answers: [3],
      },
      {
        question: 'How do bees help our environment?',
        options: [
          'They help plants grow and make food',
          'They do not help, they just make honey',
          'They eat other insects',
        ],
        answers: [1],
      },
    ],
  },
  {
    id: 12,
    title: 'Climate Action',
    color: '#c21729',
    questions: [
      {
        question: 'What is renewable energy?',
        options: [
          'A brand of an energy drink',
          'Energy generated from natural resources—such as sunlight and wind',
          'A source of energy with a renewable subscription fee',
        ],
        answers: [2],
      },
      {
        question: 'Which of the following statements is correct?',
        options: [
          "Global warming doesn't exist because last winter was really cold",
          "Global warming doesn't exist because there are still global icecaps",
          'Global warming will lead to more flooding and heavy storms',
        ],
        answers: [3],
      },
      {
        question: 'Countries should make sure they use more:',
        options: [
          'Coal and oil for energy',
          'Nuclear energy',
          'Energy from the sun, wind and waves',
        ],
        answers: [3],
      },
      {
        question: 'Who is in charge of looking after the planet?',
        options: [
          'Scientists',
          'Famous people',
          'Government officials',
          'Everyone',
        ],
        answers: [4],
      },
      {
        question:
          'In 2016, where did world leaders agree to a plan for our climate?',
        options: [
          'Paris (Paris Agreement)',
          'London (Big Ben Agreement)',
          'New York (The Manhattan Agreement)',
        ],
        answers: [1],
      },
    ],
  },
  {
    id: 13,
    title: 'Life Below Water',
    color: '#007dbb',
    questions: [
      {
        question: 'Which of the following contribute to marine pollution...',
        options: ['Litter on the beach', 'Jellyfish and crabs', 'Seaweed'],
        answers: [1],
      },
      {
        question: 'What is overfishing?',
        options: [
          'Eating too much fish and getting sick',
          'Taking more fish out of the sea than can be replaced naturally',
          'Overfeeding fish so they get bigger',
        ],
        answers: [2],
      },
      {
        question:
          'What percentage of all the species living in the ocean has been identified?',
        options: ['Around 9%', 'Around 50%', 'More than 80%'],
        answers: [1],
      },
      {
        question:
          "True or false: if we don't change our habits by 2050 there will be more plastic in the ocean than there are fish.",
        options: ['True', 'False'],
        answers: [1],
      },
      {
        question:
          'Plastic bags are dangerous for turtles because sometimes they think they are...',
        options: ['Food', 'A toy', 'A shell'],
        answers: [1],
      },
    ],
  },
  {
    id: 14,
    title: 'Life on Land',
    color: '#339b47',
    questions: [
      {
        question:
          'We must protect our forests to fight climate change because:',
        options: [
          'Forest are nice places to play',
          'Trees produces oxygen',
          'We need wood for house building',
        ],
        answers: [2],
      },
      {
        question: 'The extinction of animal species is due to...',
        options: [
          'Human activities',
          'Animals eating each other',
          'The frequency emissions of mobile phones',
        ],
        answers: [1],
      },
      {
        question: 'Biodiversity is...',
        options: [
          'The diversity of animal and plant species living on our planet',
          'The variety of healthy products you can find in a supermarket',
          'The number of planets where humans could live',
        ],
        answers: [1],
      },
      {
        question: 'Globally, forests are...',
        options: [
          'Growing, there are trees everywhere',
          'Being cut down at the same rate they are being replanted',
          'Vanishing, around half of all forests have already disappeared',
        ],
        answers: [3],
      },
      {
        question: 'Trees are essential because...',
        options: [
          'They are a habitat for wildlife',
          'You can climb them',
          'Plastic is produced from trees',
        ],
        answers: [1],
      },
    ],
  },
  {
    id: 15,
    title: 'Peace Justice and Strong Institutions',
    color: '#4a7536',
    questions: [
      {
        question: 'Which of the following is a democracy?',
        options: [
          'A country ruled by a dictator',
          'A country ruled by the people',
          'A country ruled only by men',
        ],
        answers: [2],
      },
      {
        question: 'What is a child soldier?',
        options: [
          'A child who plays war games with other children',
          'A child who is enrolled in an armed group',
          'Both',
        ],
        answers: [2],
      },
      {
        question: 'A "blue helmet" is...',
        options: [
          'A soldier who works for the United Nations to maintain peace',
          'A firefighter in the navy',
          'A safety officer',
        ],
        answers: [1],
        extraText: 'Over 100,000 military, police, and civilian personnel',
      },
      {
        question: 'What is a refugee camp?',
        options: [
          'A summer camp in the mountains',
          'A camp for the protection of people when they flee from their homes',
          'A military camp',
        ],
        answers: [2],
      },
      {
        question: 'What is child displacement?',
        options: [
          'When a child goes on holiday',
          'When a child is forced to move away from their home due to armed conflict',
          "When a child spends the night at a friend's home",
        ],
        answers: [2],
      },
    ],
  },
  {
    id: 16,
    title: 'Partnership for the Goals',
    color: '#1f3361',
    questions: [
      {
        question: 'A partnership is...',
        options: [
          'When you are playing with a friend and you pretend to be parents',
          'When people and organisations support each other to achieve a common goal',
          'When two pupils eat at the same table every day',
        ],
        answers: [2],
      },
      {
        question: 'How does fair-trade contribute to the SDGs?',
        options: [
          'It offers better conditions for producers and workers',
          'It increases profits for industries',
          'It encourages producers to hire children',
        ],
        answers: [1],
      },
      {
        question: 'What has made communication among partners easier?',
        options: ['Messenger pigeons', 'The internet', 'Messages in bottles'],
        answers: [2],
      },
      {
        question:
          'What is needed to achieve the 17 Sustainable Development Goals?',
        options: [
          'The involvement of all countries',
          'The developed countries leading the change',
          'The developing countries leading the change',
        ],
        answers: [1],
      },
      {
        question:
          'Who should be involved in partnerships to achieve the 17 SDGs?',
        options: ['Citizens', 'Governments', 'Companies', 'All of the above'],
        answers: [4],
      },
    ],
  },
];

export default sdgQuestions;
