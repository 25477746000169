<template>
  <div class="game">
    <img
      ref="logo"
      class="game__logo"
      @load="logoLoaded"
      src="~@/assets/imgs/corner-logo.png"
      alt="Irish Aid Our World Awards"
    />
    <main class="game__content">
      <RouterView></RouterView>
    </main>
    <img
      ref="background"
      @load="backgroundLoaded"
      class="game__background"
      src="~@/assets/imgs/background.png"
      alt="Background"
    />
  </div>
</template>

<script>
import { gsap } from 'gsap';
export default {
  name: 'GameRoute',
  components: {},
  methods: {
    logoLoaded() {
      gsap.fromTo(
        this.$refs.logo,
        {
          y: '-10',
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          y: '0',
          duration: 0.5,
          delay: 1,
        }
      );
    },
    backgroundLoaded() {
      gsap.fromTo(
        this.$refs.background,
        {
          y: '-10',
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          y: '0',
          duration: 1,
          delay: 1.25,
        }
      );
    },
  },
};
</script>

<style scoped>
.game {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.game__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  z-index: 5;
}

/* Logo */
.game__logo {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 300px;
  z-index: 20;
}

@media only screen and (min-width: 1200px) {
  .game__logo {
    width: 400px;
  }
}

@media only screen and (min-width: 1400px) {
  .game__logo {
    width: 500px;
  }
}

.game__background {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: 0;
}
</style>
