<template>
  <transition appear :css="false" @enter="enter" @leave="leave">
    <RouteWrapper>
      <main class="play-route">
        <div class="play-container">
          <section ref="wheelContainer" class="wheel-container">
            <Wheel
              ref="wheel"
              :sectors="wheelSectors"
              @clack="wheelClack"
              @finished="spinComplete"
              @nearlyFinished="spinNearlyFinished"
            ></Wheel>
            <Arrow ref="arrow" class="arrow"></Arrow>
          </section>
          <footer ref="buttonContainer" class="button-container">
            <Button @click="spin()">Spin It</Button>
          </footer>
        </div>
        <RouterView></RouterView>
        <Stopwatch ref="stopwatch"></Stopwatch>
        <Score ref="score"></Score>
        <img
          ref="doodle1"
          class="doodle-1"
          src="~@/assets/imgs/doodle-1.png"
          alt="Doodle"
        />
        <img
          ref="doodle2"
          class="doodle-2"
          src="~@/assets/imgs/doodle-2.png"
          alt="Doodle"
        />
      </main>
    </RouteWrapper>
  </transition>
</template>

<script>
import Wheel from '@/components/Wheel.vue';
import Button from '@/components/Button.vue';
import Arrow from '@/components/Arrow.vue';
import Stopwatch from '@/components/Stopwatch.vue';
import Score from '@/components/Score.vue';
import RouteWrapper from '@/components/RouteWrapper';
import { categories, resetGame, isOver } from '@/game';
import { timer } from '@/timer';
import { gsap } from 'gsap';

export default {
  name: 'PlayRoute',
  components: {
    Wheel,
    Button,
    Arrow,
    RouteWrapper,
    Stopwatch,
    Score,
  },
  computed: {
    // Map the categories to a simple format the wheel can work with
    wheelSectors() {
      const completedIndexes = categories.completed.map(
        (category) => category.id
      );
      return categories.all.map((category, i) => {
        return {
          text: category.title,
          color: category.color,
          enabled: !completedIndexes.includes(i),
        };
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Anytime we're arriving on this route, make sure the interface is reset
    if (to.name === 'play') {
      this.$refs.wheel.unfocus();
      this.reverseZoomWheel();
      this.showButton();
    }

    // If we're coming back from a correct/incorrect quesiton, we need to update
    // the wheel to disable the latest question that has been asked
    if (
      from.name === 'question-incorrect' ||
      from.name === 'question-correct'
    ) {
      if (isOver()) {
        this.$router.replace({ name: 'finished' });
      }

      if (categories.completed.length > 0) {
        this.$refs.wheel.disable(
          categories.completed[categories.completed.length - 1].id
        );
      }
    }
    next();
  },
  mounted() {
    resetGame();
    this.$refs.wheel.reset();
  },
  methods: {
    // Animation entering the route
    enter(el, done) {
      categories.scoreVisible = true;
      gsap
        .timeline({
          onComplete: done,
        })
        .delay(0.5)
        .fromTo(
          this.$refs.wheelContainer,
          {
            y: '-300%',
          },
          {
            y: 0,
            duration: 0.75,
            ease: 'back.out(.8)',
          },
          0
        )
        .fromTo(
          this.$refs.buttonContainer,
          {
            y: '300%',
          },
          {
            y: 0,
            duration: 0.25,
            ease: 'back.in(.8)',
          },
          0
        )
        .fromTo(
          this.$refs.arrow.$el,
          {
            x: '-500%',
            opacity: 0,
          },
          {
            x: '-120%',
            opacity: 1,
            duration: 0.1,
            delay: 0.35,
          },
          0.25
        )
        .fromTo(
          this.$refs.score.$el,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.stopwatch.$el,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.doodle1,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.doodle2,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.5,
          },
          0
        );
    },
    // Animations leaving the route
    leave(el, done) {
      gsap
        .timeline({
          onComplete: done,
        })
        .fromTo(
          this.$refs.wheelContainer,
          {
            y: 0,
          },
          {
            y: '-300%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        )
        .fromTo(
          this.$refs.buttonContainer,
          {
            y: 0,
          },
          {
            y: '300%',
            duration: 0.5,
            ease: 'back.in(.8)',
          },
          0
        )
        .fromTo(
          this.$refs.score.$el,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.stopwatch.$el,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.doodle1,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        )
        .fromTo(
          this.$refs.doodle2,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.5,
          },
          0
        );
    },
    // Hide the spin button when spinning
    spin() {
      this.$refs.wheel.spin();
      this.hideButton();
    },
    // Zoom the wheel when 75% complete on a spin
    spinNearlyFinished() {
      if (timer.isEnabled) {
        timer.hide();
      }
      this.zoomWheel();
    },
    // Focus the current segment and move to the question routes
    spinComplete(i) {
      this.$refs.wheel.focus(i);
      this.$router.replace(`/game/play/category/${i}/choose-question`);
    },
    // Animate the wheel zoom
    zoomWheel() {
      categories.scoreVisible = false;
      gsap.to(this.$refs.wheelContainer, {
        duration: 2,
        scale: 2,
        x: '100%',
        ease: 'power2.out',
      });
    },
    // Animate the reverse wheel zoom
    reverseZoomWheel() {
      categories.scoreVisible = true;
      gsap.to(this.$refs.wheelContainer, {
        duration: 1,
        scale: 1,
        x: 0,
        ease: 'power2.in',
      });
    },
    // Animate button hiding
    hideButton() {
      gsap.to(this.$refs.buttonContainer, {
        y: '300%',
        duration: 0.25,
        ease: 'back.in(.8)',
      });
    },
    // Animate button show
    showButton() {
      gsap.to(this.$refs.buttonContainer, {
        y: 0,
        duration: 0.25,
        delay: 1,
        ease: 'back.in(.8)',
      });
    },
    wheelClack() {
      // TODO: Bump arrow on every clack
    },
  },
};
</script>

<style scoped>
.play-route {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.play-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  margin-top: 50px;
}

.wheel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vw;
  width: 95vw;
  z-index: 10;
}

@media only screen and (min-width: 1000px) {
  .wheel-container {
    height: 70vh;
    width: 70vh;
  }
}

.button-container {
  margin-top: 20px;
  z-index: 15;
}

.arrow {
  position: absolute;
  left: 0;
  transform: translate3d(-120%, 0, 0);
  width: 50px;
}

@media only screen and (min-width: 600px) {
  .arrow {
    width: 75px;
  }
}

@media only screen and (min-width: 800px) {
  .arrow {
    width: 150px;
  }
}

.doodle-1 {
  z-index: -1;
  position: absolute;
  width: 20vw;
  bottom: 10vh;
  left: 8vw;
}

.doodle-2 {
  z-index: -1;
  position: absolute;
  width: 11vw;
  bottom: 30vh;
  right: 8vw;
}
</style>
